<template>
  <section class="kech-list" :class="{'no-mart' : cellType == 'receive'}">
    <van-list :loading="loading" loading-text="加载中..." :finished="finished" :immediate-check="false" finished-text="没有更多了" @load="onLoad" :offset="10">
      <van-cell v-for="(item ,index) in courseList" :key="index">
        <li class="item-mokao" v-if="cellType == 'mokao'" @click="toMock(item)">
          <div class="count" v-if="false">{{item.mock_user_nums}}已报名</div>
          <div class="item-lt">
            <img :src="item.thumb" class="lt-img">
          </div>
          <div class="item-rt">
            <div class="title f-owt-two" v-html="item.title"></div>
            <div class="exam_time_tlt">考试时间</div>
            <div class="exam_time">{{item.exam_time[0].split('：')[1]}}</div>
            <button :disabled="item.is_end == 1" @click.stop="mockRegister(item)" class="click" :class="[item.is_end == 1 ? 'btn-01' : '', item.is_register ? 'hasRegister' : '']">{{ item.is_end == 1 ? '活动已结束' : (item.is_register ? (item.subject_id == 8 ? '报名成功，点击可更改机构性质' : '报名成功，点击可更改地区') : '我要报名') }}</button>
          </div>
        </li>
        <li class="item" :class="{'receive': (cellType == 'receive')}" @click="toDetail(item)" v-else>
          <div class="item-lt">
            <img :src="item.thumb" class="lt-img">
          </div>
          <div class="item-rt">
            <span class="type" v-if="item.type == 0">直播</span>
            <span class="type" v-if="item.type == 1">录播</span>
            <span class="type" v-if="item.type == 7">合集</span>
            <div class=" f-owt-two f-owt-quan" v-html="item.title01"></div>
            <div class="zt-quan" v-if="item.is_coupon">
              <span class="zt-quan_img"> </span>
              <span class="zt-coupon">
                <span class="zt-coupon_txt">{{item.coupon_tag}}</span>
              </span>

            </div>
            <div :class="!item.is_coupon? 'no_quan rt-bt':'rt-bt'">
              <div class="bt-lt">
                <div class="price-list" v-if="item.type == 7"><span class="price-icon">¥</span>{{item.price}}<span class="suffix">起</span></div>
                <div class="price-list" v-else><span class="price-icon">¥</span>{{item.price}}
                  <span class="price-origin" v-if="item.is_crossed_price">
                    <!-- v-if="item.is_crossed_price" -->
                    <span class="price-icon">¥</span>{{item.crossed_price}}
                  </span>
                </div>
                <div class="status" v-if="item.group_status == 1 && item.limit_number">{{item.limit_number}}人团</div>
                <div class="spike" v-if="item.flag == 3"></div>
              </div>
              <!-- <div class="bt-rt" v-if="item.limit_num > 0">{{item.buy_num}}/{{item.limit_num}} 限购</div> -->
              <!-- <div class="bt-rt" v-else>{{item.buy_num}} 已购</div> -->
              <div v-if="cellType == 'receive'" class="receive-status" :class="[item.hadReceive ? (item.is_receive == 0 ? 'shop-car' : 'hadRec') : 'noRec']" @click.stop="onReceive(item)">
                <div class="icon-pre"></div>
                <div class="status-txt">{{item.hadReceive ? (item.is_receive == 0 ? '去购买' : '已领取') : '去领取'}}</div>
              </div>
            </div>
          </div>
        </li>
      </van-cell>
    </van-list>
    <vantEmpty :show="show" :type="type" :text="text" :linkTel='linkTel'></vantEmpty>
  </section>
</template>
<script>
import { reactive, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
export default {
  name: 'kech-list',
  props: {
    courseList: Object,
    loading: Boolean,
    finished: Boolean,
    type: String,
    show: Boolean,
    text: String,
    linkTel: String,
    cellType: {
      type: String,
      default: 'other'
    }
  },
  setup(props, context) {
    const { proxy } = getCurrentInstance()
    const $router = useRouter()
    const state = reactive({
      // empty_show: false
      toDetailCourse: null
    })

    const onLoad = () => {
      context.emit('onLoad')
    }

    const toDetail = (course) => {
      console.log('course+++++', course)
      state.toDetailCourse = course
      if (course.platform == 8) {
        window.location.href = course.mv_url
      } else {
        if (course.shop_id == 0) {
          let url = `/api/course/ref_id/${course.current_shop_id}/${course.id}`
          proxy.$http
            .get(url)
            .then((res) => {
              if (res.data.code == 200) {
                res.data.data == null
                  ? onGetRefIdFail()
                  : doToDetail(res.data.data.ref_id, course)
              } else {
                onGetRefIdFail()
              }
            })
            .catch((err) => {
              onGetRefIdFail()
            })
        } else {
          doToDetail(course.ref_id, course)
        }
      }
    }

    const onGetRefIdFail = () => {
      proxy.$toast('关联ID获取失败')
    }

    const doToDetail = (ref_id, course) => {
      let params = {
        dis_id: ref_id
      }
      let query = {}
      if (ref_id == 0) {
        query.dis_id = state.toDetailCourse.id
      }
      context.emit('toDetailEvent')
      $router.push({
        name: 'detail',
        params: params,
        query: query
      })
      $router.push({
        name: 'detail',
        params: params,
        query: query
      })
    }

    const toMock = (mockItem) => {
      console.log('mockItem', mockItem)
      $router.push({
        name: 'mock',
        params: {
          mock_id: mockItem.ref_id
        }
      })
    }
    const mockRegister = (mockItem) => {
      console.log('mockItem---------', mockItem)

      context.emit('mockRegister', mockItem)
    }
    const onReceive = (courseItem) => {
      if (!courseItem.hadReceive) {
        // 点击了“去领取”按钮
        console.log('去领取')
        context.emit('onReceive', courseItem)
      } else if (courseItem.hadReceive && !courseItem.is_receive) {
        // 点击了“去购买”
        console.log('去购买')
        toDetail(courseItem)
      } else {
        console.log('已领取')
        toDetail(courseItem)
      }
    }

    return {
      state,
      onLoad,
      toDetail,
      toMock,
      onReceive,
      mockRegister
    }
  }
}
</script>

<style scoped lang="scss">
.kech-list {
  margin-top: 13px;
  .item {
    display: flex;
    justify-content: flex-start;
    // align-items: center;
    align-items: flex-start;
    padding: 16px 0px;
    margin: 0 15px;
    border-bottom: 1px solid #f0f0f0;
    background: #ffffff;
    .item-lt {
      width: 120px;
      height: 74px;
      background: url(../../assets/image/home/bg-zw.png) center/100%,
        100% no-repeat;
      border-radius: 5px;
      overflow: hidden;
      .lt-img {
        width: 100%;
        height: 100%;
      }
    }
    .item-rt {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      padding-left: 12px;
      // height: 74px;
      flex: 1;
      position: relative;
      .title {
        text-align: start;
      }
      .active {
        padding-left: 42px;
      }
      .type {
        position: absolute;
        top: 4px;
        left: 12px;
        width: 36px;
        display: inline-block;
        background: linear-gradient(120deg, #fe7000 0%, #fc4607 100%);
        border-radius: 0px 3px 3px 3px;
        opacity: 0.79;
        color: #ffffff;
        line-height: 17px;
        text-align: center;
        font-size: 10px;
      }
      .zt-quan {
        width: 100%;
        display: flex;
        height: 20px;
        margin: 8px 0;
        position: relative;
        .zt-quan_img {
          width: 22px;
          height: 16px;
          background: url(../../assets/image/home/quan2.png) center/100%,
            100% no-repeat;
          position: absolute;
          top: 0;
          left: 0;
          /* width: 100%; */
          /* height: 100%; */
          z-index: 1;
        }
        .zt-coupon {
          box-sizing: border-box;
          background: url(../../assets/image/home/coupon1.png) no-repeat center;
          background-size: 100% 100%;
          min-width: 40px;
          height: 16px;
          line-height: 15px;
          text-align: center;
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          // z-index: 1;
          /* z-index: 2; */
          /* bottom: 5px; */
          /* right: 10px; */
          position: absolute;
          left: 20px;
        }
        .zt-coupon_txt {
          font-size: 10px;
          text-align: center;
          margin-top: 0;
          font-weight: 600;
          background: linear-gradient(180deg, #ff4e45 0%, #ff4e45 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          padding: 0 5px 0 7px;
        }
      }
      .no_quan {
        padding-top: 20px;
      }
      .rt-bt {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .bt-lt {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .status {
            width: 34px;
            height: 16px;
            background: linear-gradient(231deg, #fe2f00 0%, #ff53b3 100%);
            border-radius: 0px 4px 4px 4px;
            color: #ffffff;
            margin-left: 4px;
            line-height: 16px;
            text-align: center;
            font-size: 10px;
            margin-top: 4px;
          }
          .suffix {
            font-size: 11px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #fc6c21;
          }
          .spike {
            margin-left: 4px;
            background: url(https://file.huatu.com/static/miniprogram/shop/h5/spike/icon-courselist-spike@2x.png)
              no-repeat 0 0;
            background-size: cover;
            width: 34px;
            height: 16px;
          }
        }
        .bt-rt {
          font-size: 13px;
          color: #999999;
          line-height: 18px;
          padding-top: 1px;
        }
      }
    }
  }
  .item:last-child {
    border: nonw;
  }
  .item-mokao {
    display: flex;
    justify-content: flex-start;
    // align-items: center;
    padding: 12px 0px 6px;
    margin: 0 15px;
    border-bottom: 1px solid #f0f0f0;
    background: #ffffff;
    // position: relative;
    .item-lt {
      width: 120px;
      height: 74px;
      background: url(../../assets/image/home/bg-zw.png) center/100%,
        100% no-repeat;
      border-radius: 5px;
      overflow: hidden;
      .lt-img {
        width: 100%;
        height: 100%;
      }
    }
    .item-rt {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      padding-left: 12px;
      // height: 74px;
      flex: 1;
      // position: relative;
      .title {
        text-align: start;
      }
      .exam_time_tlt,
      .exam_time {
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 1.5;
      }
      .exam_time_tlt {
        margin-top: 5px;
      }
    }
    .count {
      top: 0;
      left: 0;
      position: absolute;
      width: 83px;
      height: 20px;
      background: linear-gradient(120deg, #fe7000 0%, #fc4607 100%);
      border-radius: 4px 0px 4px 0px;
      opacity: 0.79;
      color: #fff;
      line-height: 20px;
      text-align: center;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .mokao-title {
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-top: 32px;
      height: 21px;
      line-height: 21px;
      width: 94%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
    }
    .time {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-top: 5px;
      height: 18px;
      line-height: 18px;
    }
    .click {
      width: 215px;
      height: 30px;
      background: linear-gradient(90deg, #ff9d5b 0%, #fc6c21 100%);
      border-radius: 22px;
      line-height: 30px;
      text-align: center;
      color: #fff;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      margin-top: 8px;
      // margin-bottom: 12px;
    }
    .hasRegister {
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      color: #fc6c21;
      font-family: PingFangSC-Regular, PingFang SC;
      border: 1px solid #fc6c21;
      background: transparent;
    }
    .btn-01 {
      border: 1px solid #c4c4c4;
      color: #fff;
      background: #c4c4c4;
    }
  }
  .item.receive {
    .bt-rt {
      flex-grow: 1;
      align-items: center;
      text-align: start;
      margin-left: 7px;
    }
    .receive-status {
      display: flex;
      align-items: center;
      .icon-pre {
        width: 14px;
        height: 14px;
        margin-right: 3px;
      }
      .status-txt {
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
      }
      &.noRec {
        .icon-pre {
          background: url(https://file.huatu.com/static/miniprogram/shop/h5/receive/icon-receive-noRec@2x.png)
            no-repeat 0 0;
          background-size: cover;
        }
        .status-txt {
          color: #fc6c21;
        }
      }
      &.hadRec {
        .icon-pre {
          background: url(https://file.huatu.com/static/miniprogram/shop/h5/receive/icon-receive-hadRec@2x.png)
            no-repeat 0 0;
          background-size: cover;
        }
        .status-txt {
          color: #999999;
        }
      }
      &.shop-car {
        .icon-pre {
          background: url(https://file.huatu.com/static/miniprogram/shop/h5/receive/icon-receive-shop-car@2x.png)
            no-repeat 0 0;
          background-size: cover;
        }
        .status-txt {
          color: #fe5707;
        }
      }
    }
  }
}
.no-mart {
  margin-top: 0px;
}
/deep/ .van-cell {
  padding: 0 !important;
  background: none !important;
  &::after {
    border-bottom: none !important;
  }
}
.f-owt-quan {
  // max-height: 44px;
  // display: table;
  // height: auto;
  // max-height: 67px;
  height: 44px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  /* display: table; */
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  /* height: 50px; */
  // /* display: block
}
</style>