<template>
  <div class="tabbar">
    <van-tabbar route v-model="active" @change="tabChange" active-color="#FF8C3D" inactive-color="#666666" style="z-index: 100" placeholder>
      <van-tabbar-item to="/index">
        <template #icon="props">
          <button>
            <img :src="active == 0 ? icon[0].active : icon[0].inactive" :alt="props.active" />
            <span>首页</span>
          </button>
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/group">
        <template #icon="props">
          <button>
            <img :src="active == 0 ? icon[1].active : icon[1].inactive" :alt="props.active" />
            <span>我的拼团</span>
          </button>
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <template #icon="props">
          <button>
            <img :src="active == 1 ? icon[2].active : icon[2].inactive" :alt="props.active" />
            <span>个人中心</span>
          </button>
        </template>
        <div v-if="isShowIconNew" class="icon-new"></div>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { ref, computed, onMounted, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getStore, isEmptyObject } from '../../utils/util'
export default {
  name: 'tabbar',
  setup() {
    const active = ref(0)
    const $route = useRoute()
    const userInfo = getStore('userInfo')
    const { proxy } = getCurrentInstance()
    const is_receive = ref(true)
    const is_pcEquipment = ref(true)
    const myUrl = ref(' ') //个人中心路由跳转的url
    const store = useStore()
    const icon = [
      {
        active:
          'https://file.huatu.com/static/miniprogram/shop/h5/tabbar/home_active.png',
        inactive:
          'https://file.huatu.com/static/miniprogram/shop/h5/tabbar/home_inactive.png'
      },
      {
        active:
          'https://file.huatu.com/static/miniprogram/shop/h5/tabbar/pin_active.png',
        inactive:
          'https://file.huatu.com/static/miniprogram/shop/h5/tabbar/pin_inactive.png'
      },
      {
        active:
          'https://file.huatu.com/static/miniprogram/shop/h5/tabbar/mine_active.png',
        inactive:
          'https://file.huatu.com/static/miniprogram/shop/h5/tabbar/mine_inactive.png'
      }
    ]

    const isShowIconNew = computed(() => {
      // 判断是否在mine【我的】页面
      let isPageMine = /^\/mine$/.test($route.fullPath)
      // 加一个是否领取成功的参数，与上面的isPageMine参数综合判断是否在mine图标上显示【new】icon;
      return !isPageMine && !is_receive.value
    })
    onMounted(() => {
      if ($route.path.indexOf('/index') != -1) {
        active.value = 0
      } else if ($route.path.indexOf('/mine') != -1) {
        active.value = 1
      }

      setTimeout(() => {
        getIfRecieve() // 判断是否已经领取了课程
        isPC()
      }, 100)
    })
    const isPC = () => {
      // 当前设备是移动设备
      let is_mobile = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      console.log('isis_mobile_pc', is_mobile)

      if (is_mobile) {
        is_pcEquipment.value = false
        console.log('移动端')
      } else {
        is_pcEquipment.value = true
        console.log('Pc端')
      }
    }

    // 获取是否已经领取了课程
    const getIfRecieve = () => {
      if (
        !(
          userInfo &&
          userInfo !== '' &&
          userInfo.expires_in * 1000 - new Date() > 0
        )
      ) {
        // 判断是否token过期
        return
      }
      proxy.$http
        .get('/api/course/has_preview_course')
        .then((res) => {
          if (res.data.code == 200) {
            let rId = -1
            if (isEmptyObject(res.data.data)) {
              // 未领取
              console.log('未领取')
              is_receive.value = false
            } else {
              // 已领取
              console.log('已领取')
              is_receive.value = true
              rId = res.data.data.dis_id
            }
            store.commit('globalData/addReceiveId', rId) // 将领取到的课程id传入store
          } else {
            console.log('fail:', res.data.message)
          }
        })
        .catch((err) => {
          console.log('err:', err)
        })
    }

    //个人中心 移动端设备跳转M站，电脑端跳转pc，区分测试、pre】线上环境
    const tabChange = (_active) => {
      if (_active == 2) {
        let host = window.location.host
        console.log('is_pcEquipment', is_pcEquipment.value)

        if (host.includes('localhost') || host.includes('test')) {
          myUrl.value = is_pcEquipment.value
            ? 'https://www-test.huatu.com/htzx/user/xd-index.shtml#/personalCenter/myCourses'
            : 'https://m-alpha.huatu.com/wap/xd.html#/other/userCenter?xdH5=' +
              1
        } else if (host.includes('pre')) {
          myUrl.value = is_pcEquipment.value
            ? 'https://www-test.huatu.com/htzx/user/xd-index.shtml#/personalCenter/myCourses'
            : 'https://m-alpha.huatu.com/wap/xd.html#/other/userCenter?xdH5=' +
              1
        } else {
          myUrl.value = is_pcEquipment.value
            ? 'https://huatu.com/htzx/user/xd-index.shtml#/personalCenter/myCourses'
            : 'https://m.huatu.com/wap/xd.html#/other/userCenter?xdH5=' + 1
        }
        console.log(' myUrl.value', myUrl.value)
        window.location.href = myUrl.value
      }
    }

    return {
      isShowIconNew,
      active,
      icon,
      tabChange,
      isPC,
      myUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.tabbar {
  button {
    margin: 0px;
    padding: 0px;
    border: 0px;
    outline: none;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      font-size: 10px;
      margin-top: 5px;
    }
  }
}
</style>
